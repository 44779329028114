import * as React from "react";
import { Box, Heading, Stack, Container , Image} from "@chakra-ui/react";
import RichText from "../PrismicRichText";

const Reports = React.forwardRef(({ data }, ref) => {
  return (
    <Container maxW="7xl" ref={ref} px="5" mt={{base: '6', sm: "9rem"}}>
      <Stack
        justifyContent="space-between"
        spacing="5"
        direction={{ base: "column", md: "row" }}
        
      >
        <Image
          borderRadius="16px"
          objectFit="cover"
          w="472px"
          h={{base: '200px', sm: "500px" }}
          src={data.reportCover}
          />
         
        <Box maxW="37.5rem">
          <Heading as="h3" fontSize="2xl">
            {data.reportTitle}
          </Heading>
          <Box fontSize="sm" mt="6">
            <RichText text={data.description}/>
          </Box>
        </Box>
      </Stack>
    </Container>
  );
});
export default Reports;
