import React from "react";
import { Box, Container, Heading } from "@chakra-ui/react";
import CardSwiper from "../../components/cardSwiper";

const Calendar = ({ data, from }, ref) => {
  const generateCardData = () => {
    const cardData = data.events;
    return cardData
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .map((item) => {
        return {
          id: item.id,
          title: item.title2,
          description: item.description,
          date: item.date,
        };
      });
  };
  return (
    <Box ref={ref} overflow="hidden">
      <Container pb="20" px="5" maxW="7xl">
        <Heading fontSize="2xl" as="h2" mb="10">
          {data.calendarTitle}
        </Heading>
        <CardSwiper
          overflow="visible"
          moreButton
          perView={3}
          href="events"
          buttonText={data.buttonText}
          slides={generateCardData()}
          from={from}
        />
      </Container>
    </Box>
  );
};

export default React.forwardRef(Calendar);
