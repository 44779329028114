import * as React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Navigation } from "swiper";
import {
  Heading,
  Image,
  Container,
  Box,
  Button,
  Flex,
  Stack,
} from "@chakra-ui/react";
import LeftButton from "../../assets/arrow.svg";
import RightButton from "../../assets/right.svg";
import ConvertDate from "../ConvertDate";
import { useTranslation } from "gatsby-plugin-react-i18next";


const w = typeof window !== "undefined" && window.innerWidth;

const Conferences = React.forwardRef(({ data }, ref) => {
  const { language, t } = useTranslation();

  return (
    <Container ref={ref} maxW="7xl" mb="6.25rem" zIndex="-20">
      <Heading as="h3" fontSize="2xl" mb="6">
        {data.conferenceTitle}
      </Heading>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        spaceBetween={28}
        navigation={{
          nextEl: ".right",
          prevEl: ".left",
        }}
        slidesPerView={w < 481 ? 1.2 : 3}
        scrollbar={{
          draggable: true,
        }}
      >
        {data.conferences?.map((conference, idx) => (
          <SwiperSlide
            key={`conference-${conference.id}-${idx}`}
            style={{
              width: "100%",
              height: "423px",
            }}
          >
            <Box position="relative">
              <Link to={`/conference/?id=${conference.id}`}>
                <Image
                  h="90%"
                  w="100%"
                  objectFit="cover"
                  borderRadius="24px"
                  src={conference.image}
                />
                <Stack spacing={2} p="4" pt="2">
                  <Box p="1" h="26px">
                    {conference.date && <ConvertDate date={conference.date} />}
                  </Box>
                  <Heading
                    fontFamily="Mardoto"
                    minH="68px"
                    noOfLines={3}
                    lineHeight="1.33"
                    fontSize="lg"
                  >
                    {conference.title}
                  </Heading>
                </Stack>
              </Link>
            </Box>
          </SwiperSlide>
        ))}
        <Flex
          justifyContent={{
            base: "flex-start",
            sm: "center",
          }}
          mt={3}
        >
          <Link to="/conferences">
            <Button variant="transparent" w="172px">
            {t("See All")}{/*{data.buttonText}*/}
            </Button>
          </Link>
        </Flex>
        <Flex mt="2rem">
          <Box className="left" as={Button}>
            <LeftButton />
          </Box>
          <Box className="right" as={Button}>
            <RightButton />
          </Box>
        </Flex>
      </Swiper>
    </Container>
  );
});

export default Conferences;
