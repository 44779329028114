import React from "react";
import { Flex, Container, Heading, Stack, Button, Box } from "@chakra-ui/react";
import ProductCardforNews from "../../components/productcard";
import { Link } from "gatsby-plugin-react-i18next";

const NewsSlide = React.forwardRef(({ news, data, from }, ref) => {
  return (
    <Box ref={ref} py={{ base: "10", sm: "4.25rem" }} bg="#FBFBFB">
      <Container px="5" maxW="7xl">
        <Heading
          fontSize={{ base: "2xl", sm: "3xl" }}
          as="h2"
          textAlign={{ base: "left", sm: "center" }}
        >
          {data.titleNews}
        </Heading>
        <Stack
          pt={{ base: "10", sm: "20" }}
          mx="-16px"
          px="4"
          // overflowX="scroll"
          direction={{ base: "column", lg: "row" }}
          spacing={{ base: "4", sm: "7" }}
          pb="3.75rem"
        >
          {news?.slice(0, 3).map((item, idx) => (
            <ProductCardforNews
              key={`news-slide-${item.title}-${idx}`}
              idNews={item.id}
              hrefNews="single-news"
              moreButton
              minW="325px"
              src={item.image}
              product
              date
              cardDate={item.date}
              borderRadius="24px"
              title={item.title}
              description={item.short_description}
            />
          ))}
        </Stack>
        <Flex justifyContent={{ base: "flex-start", sm: "center" }}>
          <Link to="/news">
            <Button variant="transparent" w="172px">
              {data.buttonText}
            </Button>
          </Link>
        </Flex>
      </Container>
    </Box>
  );
});

export default NewsSlide;
