import * as React from "react";
import { useQueryParam, StringParam } from "use-query-params";
import { Container, Heading } from "@chakra-ui/react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import Calendar from "../../components/newsReports/Calendar";
import NewsSlide from "../../components/newsReports/NewsSlide";
import Reports from "../../components/newsReports/Reports";
import AllReports from "../../components/newsReports/AllReports";
import Conferences from "../../components/newsReports/Conferences";
import usePrismicNewsReports from "../../hooks/usePrismicNewsReports";
import usePrismicReports from "../../hooks/usePrismicReports";
import usePrismicNews from "../../hooks/usePrismicNews";
const isBrowser = () => typeof window !== "undefined";
const NewsReports = () => {
  const { language } = useI18next();

  const section = isBrowser() && window.location.hash.replace("#", "");
  const scrollToRef = (offsetTop) => window.scrollTo(0, offsetTop);
  const offsetTop = (ref) => ref.current.offsetTop - 150;

  const eventsCalendarRef = React.useRef(null);
  const newsRef = React.useRef(null);
  const reportsRef = React.useRef(null);
  const conferencesRef = React.useRef(null);

  const data = usePrismicNewsReports();
  const reports = usePrismicReports(data.reports);

  const { news } = usePrismicNews();
  React.useEffect(() => {
    if (section === "events-calendar") {
      scrollToRef(offsetTop(eventsCalendarRef));
    }
    if (section === "news") {
      scrollToRef(offsetTop(newsRef));
    }
    if (section === "reports") {
      scrollToRef(offsetTop(reportsRef));
    }
    if (section === "conferences") {
      scrollToRef(offsetTop(conferencesRef));
    }
  }, [section]);

  return (
    <Main>
      <Container px="5" maxW="7xl" mb="3.75rem">
        <Seo
          title={data.title}
          description={data.title}
          link={`https://www.fsm.am/${language}/news-reports`}
        />
        <SmallNav>{data.navText}</SmallNav>
        <Heading
          as="h1"
          fontSize={{
            base: "2xl",
            sm: "3xl",
          }}
          fontWeight="900"
          mt={{ base: "3", sm: "5" }}
          mb="10"
        >
          {data.title}
        </Heading>
      </Container>

      {data.isShowNews && <NewsSlide ref={newsRef} news={news} data={data} />}
      <Reports ref={reportsRef} data={data} />
      <AllReports data={data} reports={reports} />
      {data.isShowEvent && (
        <Calendar ref={eventsCalendarRef} data={data} from="news-reports" />
      )}
      <Conferences ref={conferencesRef} data={data} />
    </Main>
  );
};

export default NewsReports;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
